@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Fraunces:ital,opsz,wght@0,9..144,100;0,9..144,200;0,9..144,300;0,9..144,400;0,9..144,500;0,9..144,600;0,9..144,700;0,9..144,800;0,9..144,900;1,9..144,100;1,9..144,200;1,9..144,300;1,9..144,400;1,9..144,500;1,9..144,600;1,9..144,700;1,9..144,800;1,9..144,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.header {
  background-image: url(./img/mobile/image-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @apply md:h-screen md:pt-24;
}

@media (min-width: 768px) {
  .header {
    background-image: url(./img/desktop/image-header.jpg);
  }
}

.hamburger {
  @apply w-[30px] aspect-square flex md:hidden flex-col justify-center items-center cursor-pointer z-40;
}

.hamburger > span {
  @apply block h-[2px] w-full bg-white;
}

.hamburger > span:nth-child(1) {
  @apply -translate-y-[300%];
}

.hamburger > span:nth-child(3) {
  @apply translate-y-[300%];
}

.nav-mobile {
  @apply md:hidden block fixed top-[12vh] left-1/2 -translate-x-1/2 w-[85vw] h-[400px] z-40 bg-white -translate-y-[150%] opacity-0  shadow-2xl;
}

.nav-mobile-triangle {
  @apply visible relative -mt-6;
}

.nav-mobile-triangle {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 100px solid white;
  border-left: 100px solid transparent;
}

.mobile-links {
  @apply flex flex-col justify-evenly items-center h-full text-xl font-barlow font-bold text-dark-grayish-blue;
}

.mobile-links a {
  @apply py-4 px-8 rounded-full;
}

.mobile-links a:hover,
.mobile-links a:active,
.mobile-links a.active {
  @apply transition-all duration-300 ease-linear bg-yellow font-fraunces font-bold text-very-dark-desaturated-blue uppercase text-base;
}

.desktop-links {
  @apply hidden md:flex flex-row justify-evenly items-center font-barlow text-white text-xl;
}

.desktop-links a {
  @apply py-4 px-6 mx-2;
}

.desktop-links a:hover,
.desktop-links a:active,
.desktop-links a.active {
  @apply transition-all duration-300 ease-linear bg-white text-very-dark-desaturated-blue font-fraunces font-extrabold  uppercase text-sm rounded-full;
}
